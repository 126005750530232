import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    base_url:"/index.php",
    image_base_url:"/public/upload"
    //image_base_url:"http://localhost:81/public/upload",
    //base_url:"http://localhost:81/index.php",
    
    
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
