import Vue from 'vue'
import VueRouter from 'vue-router'

//解决重复push同一地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect:'/records'
  },

  {
    path: '/records',
    name: 'records',
    component: () => import('../views/Records.vue')
  },
  {
    path: '/note',
    name: 'note',
    component: () => import('../views/Note.vue')
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('../views/Timeline.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/msg',
    name: 'msg',
    component: () => import('../views/Msg.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
