import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/mavoneditor.js' 
import VueShow from 'vue-show'


Vue.config.productionTip = false

Vue.use(VueShow)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

