<template>
  <div>
    <v-app style="overflow:hidden">
      <v-app-bar app dark color="deep-purple accent-4" class="d-sm-flex d-md-none">
        <v-app-bar-nav-icon @click.stop="opendrawer()"></v-app-bar-nav-icon>

        <v-toolbar-title>个人资料储存</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click.stop="opendrawerTips()">
          <v-icon>mdi-filter</v-icon>
        </v-btn>

      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute left temporary>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              导航
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group v-model="selected" active-class="deep-purple--text text--accent-4">

            <v-list-item value="0" to="/records">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>记录</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item value="0" to="/note">
              <v-list-item-icon>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>笔记</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item value="1" to="/admin">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>博主</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item value="2" to="/msg">
              <v-list-item-icon>
                <v-icon>mdi-forum</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>留言</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item value="2" to="/timeline">
              <v-list-item-icon>
                <v-icon>mdi-chart-timeline-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>时间线</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer v-model="drawer_tips" absolute right temporary>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              分类
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group v-model="selectedType" mandatory active-class="deep-purple--text text--accent-4">
            
            <v-list-item  link v-for="item of types" :key="item.id" :value="item.id" @click="skip_records(item.id)">
              <v-list-item-content>
                <v-list-item-title>{{item.type}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-row>
        <v-col cols="2" class="d-none d-md-flex" v-if="mddrawer">
          <v-card class="float-md-left " flat height="100%" width="100%">
            <v-navigation-drawer absolute width="100%" v-model="mddrawer">
              <v-list>
                <v-list-item-group v-model="selected" active-class="deep-purple--text text--accent-4">

                  <v-list-item link value="0" to="/records">
                    <v-list-item-icon>
                      <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>记录</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link value="0" to="/note">
                    <v-list-item-icon>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>笔记</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link value="1" to="/admin">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>博主</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link value="2" to="/msg">
                    <v-list-item-icon>
                      <v-icon>mdi-forum</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>留言</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link value="2" to="/timeline">
                    <v-list-item-icon>
                      <v-icon>mdi-chart-timeline-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>时间线</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-col :md="topBannerCols">
          <v-card width="100%" height="30" flat class="d-none d-md-flex mt-2">
            <v-img height="60px" src="/images/bee.jpg">
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-app-bar-nav-icon color="white" @click="mddrawer=!mddrawer"></v-app-bar-nav-icon>
                <v-toolbar-title class="white--text">个人资料储存</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-app-bar>
            </v-img>
          </v-card>

          <v-main class="pt-14">
            <v-container>
              <v-btn color="primary" v-show="backTopShow" fab small dark fixed right bottom @click="$vuetify.goTo(0, 'duration')">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
              <router-view @fnc="mddrawer=!mddrawer" @hideBackToTop="hideBackToTop" @setTypes="setTypes"></router-view>

            </v-container>
          </v-main>
        </v-col>
      </v-row>
      <v-footer padless class="d-none d-md-flex">
        <v-col class="text-center" cols="12">
          <p><a href="https://beian.miit.gov.cn/#/Integrated/index"
              style="text-decoration:none;color:grey">www.zhanghuan.online All
              Rights Reserved | 蜀ICP备2021018941号 Copyright 2019-{{ new Date().getFullYear() }}</a>&nbsp;<span id='cnzz_stat_icon_1278636957'></span></p>
        </v-col>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
  //import HelloWorld from './components/HelloWorld';
  import { mdiAccount,mdiViewDashboard, mdiForum,mdiChartTimelineVariant } from "@mdi/js";
document.write(unescape("%3Cspan id='cnzz_stat_icon_1278636957'%3E%3C/span%3E%3Cscript src='https://v1.cnzz.com/z_stat.php%3Fid%3D1278636957%26show%3Dpic1' type='text/javascript'%3E%3C/script%3E"));
  export default {
    name: 'App',

    components: {
      //HelloWorld,
    },

    data: () => ({
      //
      selected: "0",
      selectedType:"0",//选中的分类，手机
      drawer: false,
      mddrawer: true,
      drawer_tips: false,
      article_type: "",
      topBannerCols: 10,
      backTopShow: true, //回到顶部按钮
      types:[],
      icons: {
        mdiAccount,
        mdiViewDashboard,
        mdiForum,
        mdiChartTimelineVariant
      }
    }),
    methods: {
      close_drawer_tips() {//关闭分类导航
        let that = this
        setTimeout(function () {
          that.drawer_tips = false
        }, 400)
      },
      skip_records(type){
        this.close_drawer_tips()
        this.$router.push("records?type="+type)
      },
      scrollToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      opendrawer() {
        this.scrollToTop()
        this.drawer = !this.drawer
      },
      opendrawerTips() {
        this.scrollToTop()
        this.drawer_tips = !this.drawer_tips
      },
      hideBackToTop() {
        this.backTopShow = false
      },
      handleScroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 100) {
          this.backTopShow = true
        }else{
          this.backTopShow = false
        }
      },
      setTypes(data){
        console.log("types:"+data)
        this.types = data
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    },
    watch: {
      'mddrawer': function (newValue) {
        if (newValue) {
          this.topBannerCols = 10

        } else {
          this.topBannerCols = 12
        }
      }
    }
  };
</script>